import React, { useReducer, useContext, useEffect, useState } from 'react';
import { getError } from '../../utils';
import { toast } from 'react-toastify';
import { DropdownButton, Table, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
	FaEdit,
	FaPlusCircle,
	FaRegTrashAlt,
} from 'react-icons/fa';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { Store } from '../../Store';
import {useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Pagination from '../../components/Pagination/Pagination';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import _banners from '../../services/banner.service.js';
import { SCOPES } from '../../permission-utils/scopes.js';
import PermissionWrapper from '../../permission-utils/PermissionWrapper.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			banners: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AdminBanners() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const [
		{ loadingFetch, errorFetch, banners, successDelete, itemQuantity },
		dispatch,
	] = useReducer(reducer, {
		banners: [],
		loadingFetch: true,
		errorFetch: '',
		itemQuantity: 0,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	let currentPosts = banners.slice(indexOfFirstItem, indexOfLastItem);

	const [siblingCount,setSiblingCount] = useState(1);
	window.addEventListener('resize', (e) => {
		if(window.innerWidth < 400) {
			setSiblingCount(0);
		} else {
			setSiblingCount(1);
		}
	});
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		const indexOfLastItem = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastItem - itemsPerPage;
		currentPosts = banners.slice(indexOfFirstItem, indexOfLastItem);
	};

	//#region REQUESTS
	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await _banners.list();
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			console.error(error);
			toast.error(getError(error));
		}
	};
	const deleteHandler = async (banner) => {
		if (window.confirm('Seguro que quieres eliminar el banner?')) {
			try {
				await _banners.delete(banner._id);
				toast.success('Banner eliminado Correctamente');
				dispatch({ type: 'DELETE_SUCCESS' });
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(errorFetch));
				toast.error(getError(err));
			}
		}
	};
	//#endregion
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo]);


	return (
		<div>
			<PermissionWrapper fn={'banners'} scopes={[SCOPES.canView]}>
				<Helmet>
					<title>Lista de Banners</title>
				</Helmet>
				<div className="container">
					{loadingFetch ? (
						<LoadingBox></LoadingBox>
					) : errorFetch ? (
						<MessageBox variant="danger">{errorFetch}</MessageBox>
					) : (
						<div>
							<div className="row adminProdBtnRow align-items-center justify-content-end">
								<div className="col-lg-2 col-sm-12 ">
									<PermissionWrapper fn={'banners'} scopes={[SCOPES.canCreate]}>
										<LinkContainer to="/AdminScreen/AdminBanners/AddBanner">
											<Button>
												{' '}
												<FaPlusCircle></FaPlusCircle>Agregar Banner
											</Button>
										</LinkContainer>
									</PermissionWrapper>
								</div>
							</div>
							<Card>
								<Table responsive hover size="sm">
									<thead>
										<tr>
											<th></th>
											<th>Nombre</th>
											<th>Activo?</th>
											<th>Es para móviles?</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{currentPosts.map((banner, i) => (
											<tr key={i}>
												<td>
													{' '}
													{banner.image && banner.image.fileLink ? (
														<img
															src={banner.image.fileLink}
															className="img-fluid rounded img-thumbnail"
														></img>
													) : (
														<img
															alt="product_photo"
															className="img-fluid rounded img-thumbnail empty-list-image-preview"
															src="/images/emptyPhoto.png"
														/>
													)}
												</td>
												<td>{banner.name}</td>
												<td>{banner.isActive ? 'SI' : 'NO'}</td>
												 <td>{banner.isMobile ? 'SI' : 'NO'}</td>
												<td className="col-1 text-center">
													<DropdownButton drop="start" title="">
														<LinkContainer
															to={`/AdminScreen/AdminBanners/EditBanner/${banner._id}`}
														>
															<DropdownItem>
																{' '}
																<FaEdit></FaEdit> Editar
															</DropdownItem>
														</LinkContainer>
														<DropdownItem onClick={() => deleteHandler(banner)}>
															<FaRegTrashAlt></FaRegTrashAlt>Eliminar
														</DropdownItem>
													</DropdownButton>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Card>
						</div>
					)}
					<div className="admin-pagination-container">

						<Pagination
							siblingCount = {siblingCount}
							className="pagination-bar"
							totalCount={itemQuantity}
							onPageChange={onPageChange}
							currentPage={currentPage}
							pageSize={itemsPerPage}
						></Pagination>
					</div>
				</div>
			</PermissionWrapper>
		</div>
	);
}
export default AdminBanners;
