import React, { useEffect, useReducer, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./Carousel.css";
import _banners from "../../services/banner.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_BANNER_REQUEST":
      return { ...state, loadingBannerFetch: true };
    case "FETCH_BANNER_SUCCESS":
      return { ...state, banners: action.payload, loadingFetch: false };
    case "FETCH_BANNER_FAIL":
      return {
        ...state,
        loadingBannerFetch: false,
        errorBannerFetch: action.payload,
      };
    default:
      return state;
  }
};

const BootstrapCarouselComponent = () => {
  const [showComponent, setShowComponent] = useState(false);

  const [{ loadingFetch, banners }, dispatch] = useReducer(reducer, {
    banners: [],
    loadingBannerFetch: true,
    errorBannerFetch: "",
  });
  const fetchData = async () => {
    try {
      dispatch({ type: "FETCH_BANNER_REQUEST" });
      const { data } = await _banners.listActive();
      dispatch({ type: "FETCH_BANNER_SUCCESS", payload: data });
      setShowComponent(true);
    } catch (err) {
      dispatch({ type: "FETCH_BANNER_FAIL", payload: err.message });
    }
  };

  useEffect(() => {
    if (showComponent === false) {
      fetchData();
    }
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    window.addEventListener('resize', handleResize);
    
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <div id="carousel-home">
      { banners.length > 0 && 
        <div className="row">
          <div className="col-12">
            <Carousel>
              {banners.filter(banner => !!banner.isMobile === isMobile).map((banner) =>
                banner.image && banner.image.fileLink ? (
                  <Carousel.Item key={banner._id}>
                    <img
                      className="d-block w-100 negrotest"
                      src={banner.image.fileLink}
                      alt={banner.title}
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                ) : null
              )}
            </Carousel>
          </div>
        </div>
      }
    </div>
  );
};

export default BootstrapCarouselComponent;
